html,
body,
#root {
  font-size: 18px;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.pageWrapper {
  flex: 1;
}

body {
  height: 100%;
  color: #231f20;
  background-color: #ffffff;
  background-attachment: fixed;
}

button {
  cursor: pointer;
}

p + p {
  margin-top: 1em;
}

h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

.PhoneInputInput {
  border: none;
  outline: none;
}
